import React from 'react';
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import Back from '../components/back/back';

const Savr = () => {
  return (
    <Layout>
      <div className="details case-study">
        <SEO title="Savr GV Design Sprint" />

        <div className="fixed-nav desktop-only">
          <a href="/">Home</a>
        </div>

        <section className="wrapper-container">
          <div className="mobile-only">
            <a href="/">Home</a>
          </div>

          <h3>Savr</h3>

          <p>
            A mock GV Design Sprint exercise for Springboard's UX Design Immersive.
            Acted as a UI/UX designer for an adjusted Google Ventures Design Sprint regarding a startup and
            their cooking/recipe mobile application.
          </p>
        </section>
        <section className="wrapper-container">
          
            <h3>Background</h3>
            <p>Savr Recipes is a new startup that wants to make it easier for people to follow and cook recipes. Through a design sprint, they hope to quickly test out possible solutions for those who were using their app as they cook.</p>
            
          
          
            <h3>Analysis of Research</h3>
            <p>
              <strong>Important Quotes</strong><br/>
              Savr Recipes recognized a total of 8 quotes as being significant. These quotes served as the initial foray into research and provided a glimpse at potential functionality.
            </p>

            <ul className="bullet-list">
              <li>showing needed kitchenware and equipment</li>
              <li>availability of voice commands</li>
              <li>accompanying videos and images</li>
              <li>helpful tips, words of encouragement</li>
              <li>concurrent step progression</li>
            </ul>

            <p>
              <strong>Interviews</strong><br/>
              A single interview was utilized to enquire about the process of trying out a new recipe. Insights included:
            </p>

            <ul className="bullet-list">
              <li>having a difficulty rating to find simple recipes</li>
              <li>having similar recipes readily available</li>
              <li>recipe time should include preparation time</li>
              <li>preparation should details other than having ingredients ready</li>
              <li>making certain UX decisions to promote voice interaction</li>
            </ul>

            <p>
              <strong>Priorities</strong><br/>
              Initial priorities were placed on the experience the user has once they have a recipe and are ready to begin cooking.
            </p>
          
          
            <h3>Ideation</h3>
            <ul className="asset-list">
              <li>
                <p className="text-center"><strong>User Map</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171279/Sprint_Map_ywrmt4.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171279/Sprint_Map_ywrmt4.png" ></img></a>
              </li>      
              <li>
                <p className="text-center"><strong>Lightning Demo</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171115/Lightning_Demo_edw4h0.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171115/Lightning_Demo_edw4h0.png" ></img></a>
                <p>
                  Rapid ideating and prioritizing of major features were promoted during a lightning demo.
                </p>

                <p>
                  Through “What’s Cooking” by Kraft, users are met with a brief overview to confirm what to expect. “Tasty” by BuzzFeed provides the opportunity for the user to move step-by-step or through a traditional list view. Lastly, “Jow” provides custom ingredients for a better experience.
                </p>
              </li>
              <li>
                <p className="text-center"><strong>Crazy 8's Sketches</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171115/Crazy_8_s_Sketches_oqv8tn.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171115/Crazy_8_s_Sketches_oqv8tn.png" ></img></a>
                <p>
                  In order to quickly start wireframes, a Crazy 8’s Sketches exercise was conducted. With the highest priority screen chosen, 8 iterations of the screen and its crucial features were sketched.
                </p>
              </li>
              <li>
                <p className="text-center"><strong>Wireframes</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171116/gv_sprint_designs_h2qas9.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171116/gv_sprint_designs_h2qas9.png" ></img></a>
                <p>
                  Key insights emphasized in the wireframes include: promoting voice commands and having video capabilities.
                </p>

                <p>
                  For promoting voice commands, subtle UX changes were made such as using “play video” rather than “view video” and having step titles be phrases.
                </p>

                <p>
                  For the video pop-up, the experience was meant to complement the list workflow while being in itself the step-by-step workflow. Users would also have opportunities to review while cooking to get step specific feedback.
                </p>
              </li>
            </ul>
          
          
            <h3>Testing</h3>
            <p>Testing the wireframes with potential users brought early attention to two main issues.</p>

            <p>
              While the “Get a head start” feature was meant to provide positivity and the opportunity to feel as if one was moving faster than expected, users exhibited a more negative reaction. A better usage of content would be an alternative.
            </p>

            <p>
                The “Like/Dislike” feature meant to provide feedback on a step basis was the subject of division - with opinions differing on its relation to the video, step description, or the step as a whole.
            </p>
          
        </section>
        <div className="project-bottom">
          <Back></Back>
          <Link to="/fareshare" title="To Next Project">
            Next Project
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Savr;